<template>
  <div style="color: #000;height: 100%;">
    <van-nav-bar title left-arrow :fixed="true" :z-index="999" @click-left="back">
      <template #right>
        <span @click.stop="liushui" class="liusui">流水明细</span>
      </template>
    </van-nav-bar>
    <div style="padding-top:46px; text-align: center;">
      <img class="jin" src="../../assets/img/jin.png" alt />
      <div class="ka">储值卡余额</div>
      <div class="yuer">
        <span style="font-size: 20px;">￥</span>
        {{list.balance?list.balance.toFixed(2):"0"}}
      </div>
      <van-button type="primary" class="anniu" @click="chongzhi">充值</van-button>
    </div>
  </div>
</template>

<script>
import userModel from "../../api/user";
import orderModel from "../../api/order";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  components: {},
  // mixins: [preventBack], //注入
  data() {
    return {
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      list: {},
      page: 0,
      size: 10,
      loading: false,
      total: 0,
      finished: false
    };
  },
  methods: {
    chongzhi() {
      this.$router.push({
        path: "top_up",
        query: {
          chizhimb: "true"
        }
      });
    },
    liushui() {
      this.$router.push({
        path: "water_subsidiary"
      });
    },
    onLoad() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });

      const data = {
        gid: this.shangjia.id,
        uid: this.userInfo ? this.userInfo.id : ""
        // uid: 1661
      };
      orderModel
        .count(data)
        .then(e => {
          loading.clear();
          this.list = e.data;
        })
        .catch(() => loading.clear());
    },
    onLoads() {
      // this.page++;
      this.onLoad();
    },
    // pushHistory() {
    //   //写入空白历史路径
    //   let state = {
    //     title: "title",
    //     url: "#"
    //   };
    //   window.history.pushState(state, state.title, state.url);
    // },

    // backziding() {
    //   this.pushHistory();
    //   window.addEventListener(
    //     "popstate",
    //     function(e) {
    //       location.href = "/user";
    //     },
    //     false
    //   );
    // },
    back() {
      // this.$router.go(-1);
      this.$router.push({
        path: "mine"
      });
    }
  },
  created() {
    this.onLoad();
  },
  mounted() {
    // this.backziding();
  }
};
</script>

<style lang="less" scoped>
.jin {
  width: 50px;
  height: 50px;
  margin-top: 100px;
}
.ka {
  font-size: 14px;
  margin-top: 20px;
}
.yuer {
  font-weight: bold;
  font-size: 36px;
  margin-top: 15px;
  // position: relative;
}
.liusui {
  font-size: 15px;
}
.anniu {
  position: absolute;
  bottom: 0;
  width: 160px;
  height: 35px;
  left: 50%;
  margin-left: -80px;
  margin-bottom: 70px;
}
</style>